/* eslint-disable @next/next/no-html-link-for-pages */
import Link from 'next/link';
import Image from 'next/image';

import { Divider } from '@/components/atoms/Divider';
import { Wrapper } from '@/components/atoms/Wrapper';
import { useIsUserLoggedIn } from '@/hooks/useIsUserLoggedIn';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';

import { SocialMediaButtons } from '../SocialMediaButtons';

export const Footer = () => {
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const { isLoggedIn } = useIsUserLoggedIn();
  const TERMS_OF_SERVICE_LINK = `/regulamin`;
  const PRIVACY_POLICY_LINK = `/regulamin?ref=privacyPolicy`;
  const FEEDBACK_LINK = 'https://fangol.clearflask.com/feedback';
  const TYPERIA_LINK = 'https://www.typeria.pl/';

  return (
    <Wrapper className="gap-8 mb-16 regular:mb-52 md:px-0 px-4" fullWidth>
      <Wrapper
        className="md:flex-row regular:md-between md:items-center wideDesktop:max-w-desktop md:max-w-laptop gap-8"
        fullWidth
      >
        <button onClick={updateInterstitialActionCount} className="hover:cursor-pointer flex">
          <Image
            src="/icons/fangol-logo.svg"
            alt="fangol-logo"
            width={128}
            height={40}
            className="hover:cursor-pointer"
            priority
          />
        </button>
        <Wrapper className="flex-col regular:flex-row text-gray-400 gap-8">
          {isLoggedIn && (
            <a href="/locker">
              <p className="cursor-pointer hover:underline">Szatnia</p>
            </a>
          )}
          <a href={FEEDBACK_LINK} target="_blank" className="hover:underline" rel="noreferrer">
            Zaproponuj zmiany 🤔
          </a>
          <Link href="/contact">
            <p className="cursor-pointer hover:underline">Kontakt</p>
          </Link>
          <a href={TERMS_OF_SERVICE_LINK} target="_blank" className="hover:underline" rel="noreferrer">
            Regulamin
          </a>
          <a href={PRIVACY_POLICY_LINK} target="_blank" className="hover:underline" rel="noreferrer">
            Prywatność
          </a>
          <a href={TYPERIA_LINK} target="_blank" className="hover:underline" rel="noreferrer">
            Typeria
          </a>
        </Wrapper>
      </Wrapper>
      <Divider className="bg-gray-700" />
      <Wrapper className="regular:flex-row regular:justify-between regular:items-center max-w-laptop gap-8" fullWidth>
        <p className="text-gray-400">© 2024 GPP DIGITAL Sp. z o.o. Wszystkie prawa zastrzeżone.</p>
        <SocialMediaButtons />
      </Wrapper>
    </Wrapper>
  );
};
