import Image from 'next/image';
import ReactGA from 'react-ga4';
import { ReactTyped } from 'react-typed';

import { Wrapper } from '@/components/atoms/Wrapper';
import { Divider } from '@/components/atoms/Divider';
import { NavbarDownloadLink } from '@/components/atoms/NavbarDownloadLink';
import { SocialMediaButtons } from '@/components/molecules/SocialMediaButtons';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { appStoreLink, googlePlayLink } from '@/data/links';

export const InfoContainerDesktop = () => {
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();

  const redirectToMainPage = () => {
    ReactGA.event('navi_main_tap');
    updateInterstitialActionCount();
    window.location.href = '/';
  };

  return (
    <Wrapper className="h-full items-center gap-6 min-w-fit hidden md:flex" row fullWidth>
      <button onClick={redirectToMainPage} className="relative hover:cursor-pointer w-[109px] h-8">
        <Image src="/icons/fangol-logo.svg" alt="fangol-logo" layout="fill" priority />
      </button>
      <Divider className="bg-gray-700 h-6" type="vertical" />
      <SocialMediaButtons className="gap-4" buttonClassName="w-4 h-4" />
      <Divider className="bg-gray-700 h-6 hidden wideDesktop:flex" type="vertical" />
      <Wrapper className="items-center gap-3 min-w-fit hidden wideDesktop:flex" row>
        <p className="text-xs font-bold text-white">Pobierz appkę</p>
        <Wrapper className="gap-2" row>
          <NavbarDownloadLink iconSource="/icons/google-play-white.svg" alt="Google Play logo" link={googlePlayLink} />
          <NavbarDownloadLink iconSource="/icons/apple-white.svg" alt="Apple Store logo" link={appStoreLink} />
        </Wrapper>
      </Wrapper>
      <Divider className="bg-gray-700 h-6" type="vertical" />
      <h1 className="text-white font-extrabold text-base">
        <ReactTyped
          strings={[
            'Piłka w <span style="color: #31C46C">skrócie</span>',
            'Newsy, memy i relacje z <span style="color: #31C46C">meczów</span>',
            'Futbolowe wrzuty. Szybko, celnie i <span style="color: #31C46C">na luzie</span>',
          ]}
          typeSpeed={40}
          backSpeed={35}
          cursorChar="."
          loop
        />
      </h1>
    </Wrapper>
  );
};
